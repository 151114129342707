<template>
  <div class="ticketMain">
    <div class="ticketLeft">
      <head-layout head-title="作业票流程"></head-layout>
      <div class="ticketStep">
        <el-steps direction="vertical" :active="ticketStep">
          <el-step title="申请" @click.native="runJump('draft')"></el-step>
          <el-step title="安全交底" @click.native="runJump('disclose')"></el-step>
          <el-step title="安全监管" @click.native="runJump('supervise')"></el-step>
          <el-step title="验收确认" @click.native="runJump('confirm')"></el-step>
          <el-step title="完成" @click.native="runJump('confirm')"></el-step>
        </el-steps>
      </div>
    </div>
    <div class="ticketRight">
      <div class="ticket-head">
        <head-layout head-title="作业票基本信息" :head-btn-options="headBtnOptions" @head-forward="handleForward"
                     @head-save="headSave('saveData')" @head-refer="headRefer" @head-cancel="headCancel"></head-layout>
        <el-button type="primary" class="ticket-head-btn">受限空间作业票</el-button>
        <el-form :model="dataForm" label-width="100px" class="ticket-head-code">
          <el-form-item label="作业票编号:" prop="ticketCode">
            <el-input disabled v-model="dataForm.ticketCode" placeholder="请输入作业票编号" style="width: 300px"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="ticketContent">
        <div class="ticketForm" id="draft">
          <el-form :disabled="['view', 'flow', 'sign', 'workflow'].includes(type)" :model="dataForm" label-width="140px"
                   :rules="rules" ref="formLayout">
            <el-row>
              <el-col :span="8">
                <el-form-item label="作业申请单位:" prop="requirOrg">
                  <el-input v-model="dataForm.requirOrg" placeholder="请选择/输入作业申请单位" maxlength="255" show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="openDeptBelow('requirOrg')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="作业单位:" prop="workOrg">
                  <el-input v-model="dataForm.workOrg" placeholder="请选择/输入作业单位" maxlength="255" show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="openDeptBelow('workOrg')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="作业申请时间:" prop="requirTime">
                  <el-date-picker v-model="dataForm.requirTime" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                                  value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择作业申请时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <!-- <el-col :span="8">
            <el-form-item label="作业人:" prop="userName">
              <el-input :disabled="isDisabled" v-model="dataForm.userName" placeholder="请选择/输入作业人"
                        maxlength="255" show-word-limit>
                <el-button slot="append" icon="el-icon-search" @click="openUser('user')"></el-button>
              </el-input>
            </el-form-item>
          </el-col> -->
              <el-col :span="8">
                <el-form-item label="作业负责人:" prop="respNickName">
                  <el-input v-model="dataForm.respNickName" placeholder="请选择/输入作业负责人" readonly maxlength="255"
                            show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="openUser('respNickName')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="监护人:" prop="guardianName">
                  <el-input v-model="dataForm.guardianName" placeholder="请选择/输入监护人" maxlength="255" show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="openUser('guardian')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="安全交底人:" prop="securityDisclosureName">
                  <el-input v-model="dataForm.securityDisclosureName" placeholder="请选择/输入安全交底人" readonly maxlength="255"
                            show-word-limit>
                    <el-button slot="append" icon="el-icon-search"
                               @click="openUser('securityDisclosureName')"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="原有介质名称:" prop="material">
                  <el-input v-model="dataForm.material" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }"
                            maxlength="255" show-word-limit placeholder="请输入原有介质名称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="作业内容:" prop="content">
                  <el-input v-model="dataForm.content" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }"
                            maxlength="500" show-word-limit placeholder="请输入作业内容"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="受限空间名称:" prop="limitName">
                  <el-input v-model="dataForm.limitName" placeholder="请选择/输入受限空间名称" maxlength="255" show-word-limit>
                    <el-button slot="append" icon="el-icon-search" @click="ledgerClick()"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <!--              <el-col :span="8">-->
              <!--                <el-form-item label="安全检查:" prop="securityCheck">-->
              <!--                  <el-select v-model="dataForm.securityCheck" placeholder="请选择安全检查">-->
              <!--                    <el-option v-for="item in securityCheckOption" :key="item.id" :label="item.typeName"-->
              <!--                      :value="item.id">-->
              <!--                    </el-option>-->
              <!--                  </el-select>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :span="12">
                <el-form-item :label="'关联其他作业票:'" prop="relatedWt">
                  <el-input v-model="dataForm.relatedWt" placeholder="请选择作业票" maxlength="255" show-word-limit readonly>
                    <el-button slot="append" @click="relatedWtClick" icon="el-icon-search"></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="14">
                <el-form-item label="受限空间作业时间:" prop="timeInterval">
                  <el-date-picker v-model="dataForm.timeInterval" type="datetimerange" format="yyyy-MM-dd HH:mm"
                                  value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                  end-placeholder="结束日期" style="width: 100%">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="风险辨识结果:" prop="wtRiskList">
                  <el-checkbox-group v-model="dataForm.wtRiskList">
                    <el-checkbox :label="item.optionCode" v-for="(item, index) in riskIdentResults" :key="index"
                                 @change="wtRiskChanger(item, '')">
                      <div v-if="item.optionCode == 'other'">
                        其他：
                        <el-input v-model="wtRiskOther" placeholder="请输入其他" :disabled="wtRiskThow" maxlength="255"
                                  show-word-limit @change="wtRiskChanger(item, 'input')"></el-input>
                      </div>
                      <div v-else>
                        {{ item.optionName }}
                      </div>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="ticketGas">
          <head-layout head-title="作业人" @head-add="addOperator" :head-btn-options="operatorHeadBtn"></head-layout>
          <div class="gasTable">
            <el-table :data="wtUserList" border size="medium" :height="300" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="userName" align="center" label="人员姓名">
              </el-table-column>
              <el-table-column prop="userDept" align="center" label="所属组织">
              </el-table-column>
              <el-table-column prop="userLicense" align="center" label="证书编号">
              </el-table-column>
              <el-table-column label="操作" align="center" width="100" v-if="['edit', 'add'].includes(type)">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="deleteOperator(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="ticketGas">
          <head-layout head-title="安全监管人" :head-btn-options="superviseHeadBtn" @head-add="addSupervise"></head-layout>
          <div class="gasTable">
            <el-table :data="safetySupervisorList" border  :height="300" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="conservatorName" align="center" label="监管人名称">
              </el-table-column>
              <el-table-column label="操作" align="center" width="100" v-if="['edit', 'add'].includes(type)">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="deleteSupervise(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="ticketGas">
          <head-layout head-title="气体取样分析结果" @head-add="addGas" :head-btn-options="gasHeadBtn"></head-layout>
          <div class="gasTable">
            <el-table :data="wtGasList" border  style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column align="center" v-for="(cloumn, index) in tableCloumns" :key="index" :label="cloumn.title"
                               :prop="cloumn.prop" :width="cloumn.prop == 'sampleTime' ? 150 : 100">
                <template v-if="cloumn.children" v-for="(columnChildren, i) in cloumn.children">
                  <el-table-column width="120" :key="i" :label="columnChildren.title" :prop="columnChildren.prop"
                                   align="center">
                    <template slot-scope="scope">
                      <el-input :disabled="['view', 'flow', 'sign', 'workflow'].includes(type)"
                                v-model="scope.row[columnChildren.prop]" size="small" maxlength="64"
                                :placeholder="columnChildren.prop | editPlaceHolder"
                                @blur="handleInputBlur(scope.row)"></el-input>
                      <!--                          <span v-else>{{ scope.row[columnChildren.prop] }}</span>-->
                    </template>
                  </el-table-column>
                </template>

                <template slot-scope="scope">
                  <el-input :disabled="['view', 'flow', 'sign', 'workflow'].includes(type)"
                            v-if="cloumn.prop == 'location'" v-model="scope.row[cloumn.prop]" size="small"
                            maxlength="255"
                            :placeholder="cloumn.prop | editPlaceHolder" @blur="handleInputBlur(scope.row)"></el-input>
                  <el-date-picker :disabled="['view', 'flow', 'sign', 'workflow'].includes(type)"
                                  v-if="cloumn.prop == 'sampleTime'" v-model="scope.row[cloumn.prop]" type="datetime"
                                  format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择取样时间">
                  </el-date-picker>
                  <div v-if="cloumn.prop == 'sampleUser'">
                    <img class="signatureImg" :src="scope.row[cloumn.prop]" v-if="scope.row.sampleUser">
                    <el-button size="small" v-if="scope.row.sampleUser == '' && ['edit', 'add'].includes(type)"
                               @click="openSignature(scope.$index, 'wtGasList')">
                      签名
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" v-if="['edit', 'add', 'sign'].includes(pageType)" align="center" width="100">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="deleteGas(scope.$index, scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="ticketSecure">
          <head-layout head-title="安全措施" @head-add="addSecure" :head-btn-options="secureHeadBtn"></head-layout>
          <div class="gasTable">
            <el-table :data="wtSecurityPreventionList" border  :height="300" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="securityContent" align="center" label="安全措施">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.securityContent" placeholder="请输入安全措施" type="textarea"
                            :autosize="{ minRows: 1, maxRows: 6 }" maxlength="255" show-word-limit
                            :disabled="['view', 'flow', 'sign', 'workflow'].includes(type)"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="invovedFlag" align="center" width="100" label="是否涉及">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.invovedFlag" active-value="1" inactive-value="0" active-text="是"
                             v-if="scope.row.type != 'other'"
                             :disabled="['view', 'flow', 'sign', 'workflow'].includes(type)"
                             inactive-text="否">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100" v-if="['edit', 'add'].includes(type)">
                <template slot-scope="scope" v-if="scope.row.type != 'other'">
                  <el-button type="text" size="small" @click="
                    deleteSecure(scope.$index, wtSecurityPreventionList)
                    ">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!--安全措施签名-->
        <div class="ticketDisclose">
          <head-layout head-title="安全措施签名"></head-layout>
          <securitySignature ref="securitySignature" :securityType="$route.query.type" :doned="$route.query.doned=='1'"
                             :businessStatus="businessStatus" @deleteImg="deleteImgSecure('measureSignatureUrl')"
                             :list="[{imgUrl: dataForm.measureSignatureUrl}]"
                             @getSecuritySignature="getSecuritySignature($event,'measureSignatureUrl')">
          </securitySignature>

        </div>
        <div class="ticketGas" v-if="processFlowList.length != 0">
          <head-layout head-title="作业票审批"></head-layout>
          <div class="gasTable">
            <el-table :data="processFlowList" border  style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50">
              </el-table-column>
              <el-table-column prop="assigneeName" align="center" label="审批人">
              </el-table-column>
              <el-table-column prop="comments" align="center" label="审批意见">
                <template slot-scope="scope">
                  <el-tag type="danger" v-if="scope.row.comments.length != 0">{{
                      scope.row.comments[0].message
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="historyActivityType" align="center" label="审批结果">
                <template slot-scope="scope">
                  <el-tag>{{
                      scope.row.historyActivityType == "endEvent"
                        ? "结束"
                        : scope.row.historyActivityName || "未命名"
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="endTime" align="center" label="审批时间">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 安全交底 -->
        <div class="ticketGas" id="disclose" v-if="['DISCLOSED', 'SUPERVISE', 'CHECKACCEPT', 'FINISHED'].includes(
          businessStatus
        ) && ['supervise', 'safeConfessor', 'respUser', 'drafter'].includes(characters)">
          <head-layout head-title="安全交底"  @head-add="addDisclose"></head-layout>
          <div class="gasTable">
            <el-form :model="discloseForm" :rules="discloseRules" ref="discloseForm" label-width="100px"
            :disabled="$route.query.doned!='1'&&(type == 'view' ||businessStatus!='DISCLOSED')">
                <el-form-item label="上传附件" prop="documentUrl">
                  <el-upload action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                  :disabled="type == 'view'&&businessStatus!='DISCLOSED'" :headers="headers" :on-success="discloseSuc"
                            :on-remove="discloseRem"
                            :on-preview="downloadFile" multiple :limit="3" :file-list="documentUrl">
                    <el-button v-if="type != 'view' &&businessStatus=='DISCLOSED'" size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </el-form-item>
           </el-form>
          </div>
        </div>
        <!--安全交底人-->
        <div class="ticketDisclose" v-if="['DISCLOSED', 'SUPERVISE', 'CHECKACCEPT', 'FINISHED'].includes(
          businessStatus
        ) && ['supervise', 'safeConfessor', 'respUser', 'drafter'].includes(characters)">
          <securityDisclosure ref="securityDisclosure" :securityType="$route.query.type"
          :doned="$route.query.doned=='1'"
                              :businessStatus="businessStatus">
          </securityDisclosure>
        </div>
        <!-- 安全监管现场照片 -->
        <div class="ticketGas" id="supervise" v-if="['SUPERVISE', 'CHECKACCEPT', 'FINISHED'].includes(businessStatus) &&
          ['supervise', 'safeConfessor', 'respUser', 'drafter'].includes(
            characters
          )
        ">
          <superviseImg ref="superviseImg" :disabled="$route.query.doned!='1'&&type == 'flow' && characters == 'supervise'"></superviseImg>
        </div>
        <!-- 完工确认 -->
        <div class="ticketGas" id="confirm" v-if="['CHECKACCEPT', 'FINISHED'].includes(businessStatus) &&
          ['supervise', 'safeConfessor', 'respUser', 'drafter'].includes(
            characters
          )
        ">
          <finishWorkImg ref="finishWorkImg" :disabled="$route.query.doned!='1'&&type == 'flow' && characters == 'respUser'"></finishWorkImg>
        </div>
      </div>
    </div>
    <common-dialog dialogTitle="作业票清单" v-if="jobTicketDialogShow" width="60%" :showConfirmBtn=false
                   @cancel="jobTicketDialogShow = false">
      <jobTicketDialog ref="jobTicketDialog" :ticketCode=dataForm.ticketCode @submitData="submitData"></jobTicketDialog>
    </common-dialog>
    <el-dialog title="电子签名" :visible.sync="signatureShow" width="1000px">
      <signature ref="signature" @getSignatureImg="getSignatureImg"></signature>
    </el-dialog>
    <el-dialog title="部门选择" :visible.sync="deptBelowShow" width="1000px">
      <currentDepartmentBelow @getDeptBelow="getDeptBelow"></currentDepartmentBelow>
    </el-dialog>
    <!--安全交底表单弹窗-->
    <el-dialog title="安全交底" :visible.sync="discloseShow" top="9vh" width="40%">
      <el-form :model="discloseForm" :rules="discloseRules" ref="discloseForm" label-width="100px"
               :disabled="discloseType == 'view'">
        <el-form-item label="交底人" prop="disclosureName">
          <el-input v-model="discloseForm.disclosureName" placeholder="请选择交底人">
            <el-button slot="append" icon="el-icon-search" @click="openUser('disclosureName')"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="日期时间" prop="disclosureTime">
          <el-date-picker v-model="discloseForm.disclosureTime" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="交底内容" prop="remark">
          <el-input type="textarea" v-model="discloseForm.remark" placeholder="请输入交底内容"></el-input>
        </el-form-item>
        <el-form-item label="相关文件" prop="documentUrl">
          <el-upload action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                     :disabled="discloseType == 'view'" :headers="headers" :on-success="discloseSuc"
                     :on-remove="discloseRem"
                     :on-preview="downloadFile" multiple :limit="3" :file-list="documentUrl">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="discloseShow = false">取 消</el-button>
        <el-button type="primary" @click="submitDisclse" v-if="discloseType != 'view'">确 定</el-button>
      </span>
    </el-dialog>
    <!--当前部门及一下弹窗-->
    <el-dialog title="人员选择" :visible.sync="userShow" top="9vh" width="70%">
      <ProjectSelectUser v-if="userShow" :isDel="true" :checkedData="checkedUserData" @select-data="getUser" :multiple="userOpentype == 'securityDisclosureName'" @closeDia="closeUser"></ProjectSelectUser>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="userListShow" top="9vh" width="80%">
      <UserDeptMultipleDialog ref="userDeptMultipleDialog" :checkedData="checkedUserData" v-if="userListShow" @select-all-data="getUserList"
                              @closeDia="userListShow = false">
      </UserDeptMultipleDialog>
    </el-dialog>
    <common-dialog dialogTitle="选择受限空间台账" v-if="ledgerShow" width="60%" :show-btn="false"
                   @cancel="ledgerShow = false">
      <grid-layout ref="gridLayout" class="UserDeptCrud" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
                   :tableOptions="tableOptions" :tableData="tableData" :tableLoading="tableLoading"
                   :searchColumns="searchColumns"
                   :gridRowBtn="gridRowBtn" @defBtn="ledgerRowClick">
      </grid-layout>
    </common-dialog>
    <!--流程用户弹框-->
    <process-user-dialog ref="processUser" @getUserSelection="handleUserSelection"
                         @closeProcess="closeProcess"></process-user-dialog>
    <!--作业监管人上传的附件表-->
    <jobTicketImage ref="jobTicketImage" :title="'在线预览'"></jobTicketImage>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import {
  myWtTicketDetail,
  wtRiskAll,
  wtSecurityPreventionAll, templateCode, removeWtUser,
  safetySupervisionRemove,
  securityDisclosureRemove, deleteImg
} from "@/api/workTicket";
import {list as security} from "@/api/safetyTtandard/jobActivity";
import Template from "@/views/message/template/list";
import signature from "@/views/components/signature";
import currentDepartmentBelow from "@/views/components/currentDepartmentBelow";
import UserDetpDialog from "@/views/business/jobSlip/jobTicketList/components/UserDetpDialog";
import CommonDialog from "@/components/CommonDialog";
import securityDisclosure from "@/views/business/jobSlip/jobTicketList/securityDisclosure";
import {List} from "@/api/wtLimitSpace/limitSpaceLedger";
import {
  wtLimitSave,
  Detail,
  retransmission,
  removeWtSecurityPrevention,
  removeWtLimitGas
} from "@/api/wtLimitSpace/limitSpaceWork"
import UserDeptMultipleDialog from "@/views/business/jobSlip/jobTicketList/components/UserDeptMultipleDialog";
import jobTicketDialog from "@/views/business/jobSlip/jobTicketList/jobTicketDialog";
import {dateFormat} from "@/util/date";
import {detail as processDetail, processList as getProcessList} from "@/api/plugin/workflow/process";
import {mapGetters} from "vuex";
import ProcessUserDialog from "@/views/business/components/processUserDialog";
import GridLayout from "@/views/components/layout/grid-layout";
import jobFlow from "@/views/business/jobSlip/jobTicketList/jobFlow";

import {getToken} from "@/util/auth";
import superviseImg from "@/views/business/jobSlip/jobTicketList/superviseImg";
import FinishWorkImg from "@/views/business/jobSlip/jobTicketList/finishWorkImg";
import jobTicketImage from "@/views/business/jobSlip/jobTicketList/jobTicketImage";
import ProjectSelectUser from "@/views/components/UserDeptDialog/projectSelectUser.vue";
import securitySignature from "@/views/business/jobSlip/jobTicketList/securitySignature.vue";

export default {
  name: "index",
  components: {
    securitySignature,
    Template,
    HeadLayout,
    GridLayout,
    signature,
    // UserDetpDialog,
    ProjectSelectUser,
    currentDepartmentBelow,
    CommonDialog,
    securityDisclosure,
    UserDeptMultipleDialog,
    ProcessUserDialog,
    jobTicketDialog,
    superviseImg,
    FinishWorkImg,
    jobTicketImage
  },
  mixins: [jobFlow],
  data() {
    return {
      rules: {
        requirTime: [
          {required: true, message: '请选择作业申请时间', trigger: ['blur', 'change']},
        ],
        timeInterval: [
          {required: true, message: '请选择受限空间作业时间', trigger: ['blur', 'change']},
        ],
        workOrg: [
          {required: true, message: '请选择/输入作业单位', trigger: ['blur', 'change']},
        ],
        requirOrg: [
          {required: true, message: '请选择/输入作业申请单位', trigger: ['blur', 'change']},
        ],
        respNickName: [
          {required: true, message: '请选择/输入作业负责人', trigger: ['blur', 'change']},
        ],
        userName: [
          {required: true, message: '请选择/输入作业人', trigger: ['blur', 'change']},
        ],
        guardianName: [
          {required: true, message: '请选择/输入监护人', trigger: ['blur', 'change']},
        ],
        securityDisclosureName: [
          {
            required: true,
            message: "请选择/输入安全交底人",
            trigger: ["blur", "change"],
          },
        ],
        relatedWt: [
          {required: false, message: '请选择作业票', trigger: ['blur', 'change']},
        ],
        wtRiskList: [
          {required: true, message: '请选择风险辨识结果', trigger: ['change']},
        ],
        limitName: [
          {required: true, message: '请选择/输入受限空间名称', trigger: ['blur', 'change']},
        ],
        content: [
          {required: true, message: '请输入作业内容', trigger: ['blur', 'change']},
        ],
        material: [
          {required: true, message: '请输入原有介质名称', trigger: ['blur', 'change']},
        ],
        securityCheck: [
          {required: true, message: '请选择安全检查', trigger: ['blur', 'change']},
        ]
      },
      wtRiskOther: "",
      wtRiskThow: true,
      processId: "",
      formProcess: {
        id: '',
        processId: '',
        data: {},
      },
      ticketCode: "",
      pageType: '',
      isSubmit: false,
      jobTicketDialogShow: false,
      userListShow: false,
      businessStatus: '',
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.defBtn'),
          emit: "defBtn",
          type: "text",
          icon: ""
        }
      ],
      searchColumns: [{
        prop: "limitSpaceName",
        type: "input",
        span: 3,
        placeholder: "请输入有限空间名称",
      }],
      tableData: [],
      tableLoading: false,
      tableOptions: {
        customRowAddBtn: false,
        menu: true,
        selection: false,
        column: [
          {
            label: "有限空间名称",
            prop: "limitSpaceName",
            align: "left",
            overHidden: true,
          },
          {
            label: "所在位置",
            align: "center",
            prop: "location",
            overHidden: true,
          },
          {
            label: "原有介质名称",
            prop: "originalMediaName",
            align: "center",
            overHidden: true,
          },
          {
            label: "主要危害因素",
            prop: "mainHazardFactors",
            align: "center",
            overHidden: true,
          },
          {
            label: "危害后果",
            prop: "harmfulConsequences",
            align: "center",
            overHidden: true,
          },
          {
            label: "主要控制措施",
            prop: "mainControlMeasures",
            align: "center",
            overHidden: true,
          },
          {
            label: "责任人",
            prop: "personUser",
            align: "center",
            overHidden: true,
          },
          {
            label: "责任部门",
            prop: "personDept",
            align: "center",
            overHidden: true,
          },
        ],
      },
      riskIdentResults: [],
      ledgerShow: false,
      tableIndex: null,
      tableName: '',
      dataForm: {
        measureSignatureUrl: "",
        ticketCode: '',
        requirTime: '',
        requirOrg: '',
        workOrg: '',
        respNickName: '',
        respUserDept: "",
        userName: '',
        guardianName: '',
        securityDisclosureName: "",
        securityDisclosureId: "",
        securityDisclosureCode: "",
        securityDisclosureDept: "",
        relatedWt: '',
        limitName: '',
        content: '',
        material: '',
        securityCheck: '',
        wtRiskList: [],
        timeInterval: [],
        wtDisclosureList: [],
      },
      wtGasList: [],
      securityCheckOption: [],
      tableCloumns: [
        {
          title: '分析项目1',
          prop: 'prj1',
          children: [
            {
              title: '气体名称',
              prop: 'gasName1'
            },
            {
              title: '合格标准',
              prop: 'standard1'
            },
            {
              title: '检测值',
              prop: 'sampleResult1'
            },
          ]
        },
        {
          title: '分析项目2',
          prop: 'prj2',
          children: [
            {
              title: '气体名称',
              prop: 'gasName2'
            },
            {
              title: '合格标准',
              prop: 'standard2'
            },
            {
              title: '检测值',
              prop: 'sampleResult2'
            },
          ]
        },
        {
          title: '分析项目3',
          prop: 'prj3',
          children: [
            {
              title: '气体名称',
              prop: 'gasName3'
            },
            {
              title: '合格标准',
              prop: 'standard3'
            },
            {
              title: '检测值',
              prop: 'sampleResult3'
            },
          ]
        },
        {
          title: '分析项目4',
          prop: 'prj4',
          children: [
            {
              title: '气体名称',
              prop: 'gasName4'
            },
            {
              title: '合格标准',
              prop: 'standard4'
            },
            {
              title: '检测值',
              prop: 'sampleResult4'
            },
          ]
        },
        {
          title: '分析项目5',
          prop: 'prj5',
          children: [
            {
              title: '气体名称',
              prop: 'gasName5'
            },
            {
              title: '合格标准',
              prop: 'standard5'
            },
            {
              title: '检测值',
              prop: 'sampleResult5'
            },
          ]
        },
        {
          title: '取样分析时间',
          prop: 'sampleTime'
        },
        {
          title: '分析部位',
          prop: 'location'
        },
        {
          title: '分析人',
          prop: 'sampleUser'
        }
      ],
      userOpentype: '',
      deptOpenType: '',
      userShow: false,
      signatureShow: false,
      deptBelowShow: false,
      wtSecurityPreventionList: [],
      operatorSelect: false,
      wtUserList: [],
      type: "",
      characters: "",
      processInstanceId: '',
      processFlowList: [],
      safetySupervisorList: [],
      securityDisclosureList: [],
      discloseShow: false,
      discloseForm: {},
      discloseType: "add",
      discloseRules: {
        disclosureName: [
          {
            required: true,
            message: "请选择交底人",
            trigger: ["blur", "change"],
          },
        ],
        disclosureTime: [
          {
            required: true,
            message: "请选择日期时间",
            trigger: ["blur", "change"],
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入交底内容",
            trigger: ["blur", "change"],
          },
        ],
        // documentUrl: [
        //   {required: true, message: '请选择相关文件', trigger: ['blur', 'change']},
        // ]
      },
      documentUrl: [],
      checkedUserData: []
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    gasHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "新增",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    secureHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "新增",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    operatorHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "选择人员",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    superviseHeadBtn() {
      let result = [];
      if (["edit", "add"].includes(this.type)) {
        result.push({
          label: "选择人员",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    discloseHeadBtn() {
      let result = [];
      if (this.type == "flow" && this.businessStatus == "DISCLOSED") {
        result.push({
          label: "新增",
          emit: "head-add",
          btnOptType: "add",
        });
      }
      return result;
    },
    headBtnOptions() {
      let result = [];
      if(this.$route.query.doned=='1'){
        result.push({
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });

      }else{
      if (
        (this.type == "add" ||
          ["PREPARE", "DISCLOSED", "SUPERVISE", "CHECKACCEPT"].includes(
            this.businessStatus
          ) ||
          this.type == "sign") &&
        !["view", "workflow"].includes(
          this.type)
      ) {
        result.push({
          label: "保存",
          emit: "head-save",
          btnOptType: "save",
        });
      }
      if (['add', 'edit'].includes(this.type)) {
        result.push({
          label: "转发",
          emit: "head-forward",
          btnOptType: "forward",
        });
      }
      if (
        (this.type == "add" ||
          [
            "PREPARE",
            "CONFIRMING",
            "DISCLOSED",
            "SUPERVISE",
            "CHECKACCEPT",
          ].includes(this.businessStatus)) &&
        !["view", "workflow"].includes(
          this.type)
      ) {
        result.push({
          label: "提交",
          emit: "head-refer",
          loading: this.isSubmit,
          btnOptType: "refer",
        });
      }
      if (this.type != "workflow") {
        result.push({
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });
      }}
      return result;
    },
  },
  filters: {
    editPlaceHolder(value) {
      let str = ''
      if (value.indexOf('sampleResult') > -1) str = '检测值'
      if (value.indexOf('gasName') > -1) str = '气体名称'
      if (value.indexOf('standard') > -1) str = '合格标准'
      if (value.indexOf('location') > -1) str = '分析部位'
      return '请输入' + str
    },
  },
  created() {
    let routeData = this.$route.query
    this.type = routeData.type;
    this.pageType = routeData.type
    this.businessStatus = routeData.businessStatus || 'PREPARE'
    this.characters = routeData.characters;
    this.processInstanceId = routeData.processInstanceId;
    // 安全检查
    security().then((res) => {
      if (res.data.code == 200) {
        this.securityCheckOption = res.data.data;
      }
    })
    wtRiskAll("yxkj").then((res) => {
      if (res.data.code == 200) {
        this.riskIdentResults = res.data.data;
      }
    })
    if (this.processInstanceId && this.processInstanceId != ''&& this.processInstanceId != "null") {
      this.getProcessDetail();
    }
    if (this.pageType == 'add') {
      this.getTemplateCode();
      this.dataForm.requirTime = dateFormat(new Date())
      //作业单位默认登陆人组织
      this.dataForm.requirOrg = this.userInfo.deptName;
      this.dataForm.workOrg = this.userInfo.deptName;
      this.wtGasList = [{
        gasName1: '硫化氢', standard1: '<10ppm', sampleResult1: '',
        gasName2: '一氧化碳', standard2: '<50ppm', sampleResult2: '',
        gasName3: '甲烷', standard3: '<20%lel', sampleResult3: '',
        gasName4: '', standard4: '', sampleResult4: '',
        gasName5: '氧气', standard5: '19.5%-23.5%', sampleResult5: '', sampleUser: '',
      },
        {
          gasName1: '硫化氢', standard1: '<10ppm', sampleResult1: '',
          gasName2: '一氧化碳', standard2: '<50ppm', sampleResult2: '',
          gasName3: '甲烷', standard3: '<20%lel', sampleResult3: '',
          gasName4: '', standard4: '', sampleResult4: '',
          gasName5: '氧气', standard5: '19.5%-23.5%', sampleResult5: '', sampleUser: '',
        },
        {
          gasName1: '硫化氢', standard1: '<10ppm', sampleResult1: '',
          gasName2: '一氧化碳', standard2: '<50ppm', sampleResult2: '',
          gasName3: '甲烷', standard3: '<20%lel', sampleResult3: '',
          gasName4: '', standard4: '', sampleResult4: '',
          gasName5: '氧气', standard5: '19.5%-23.5%', sampleResult5: '', sampleUser: '',
        }]
      List().then(res => {
        this.tableData = res.data.data
      })
      wtSecurityPreventionAll("yxkj").then((res) => {
        if (res.data.code == 200) {
          this.wtSecurityPreventionList = res.data.data;
        }
      })
      this.dataForm.respUserName = this.userInfo.account;
      this.dataForm.respUserId = this.userInfo.id;
      this.dataForm.respNickName = this.userInfo.real_name;
      this.dataForm.respUserDept = this.userInfo.deptId;
    } else {
      // this.$loading()
      // Detail(routeData.id).then(res => {
      this.initData(routeData.id)
      // this.initData(res.data.data)
      // }).finally(() => {
      //   this.$loading().close()
      // })
    }
  },
  methods: {
    //删除安全措施签名
    deleteImgSecure(type) {
      if (this.dataForm.id) {
        deleteImg(this.dataForm.ticketCode).then(res => {
          this.dataForm[type] = ""
        })
      } else {
        this.dataForm[type] = ""
      }
    },
    getSecuritySignature(imgUrl, type) {
      this.dataForm[type] = imgUrl;
    },
    // 流程详情
    getProcessDetail() {
      processDetail({processInsId: this.processInstanceId}).then((res) => {
        if (res.data.code == 200) {
          this.processFlowList = res.data.data.flow;
        }
      })
    },
    // 下载文件
    downloadFile(row) {
      this.$refs.jobTicketImage.jobTicketRow(row)
    },
    // 新增安全交底
    addDisclose() {
      this.discloseType = "add";
      this.discloseForm = {
        ticketCode: this.dataForm.ticketCode,
        remark: "",
        disclosureTime: dateFormat(new Date()),
        disclosureId: this.userInfo.id,
        disclosureName: this.userInfo.real_name,
        documentUrl: "",
      };
      this.documentUrl = [];
      this.discloseShow = true;
      this.$nextTick(() => {
        this.$refs.discloseForm.resetFields();
      });
    },
    // 编辑安全交底
    editDisclose(row) {
      this.discloseType = "edit";
      this.discloseForm = row;
      if (this.discloseForm.documentUrl == "") {
        this.documentUrl = '[]';
      } else {
        this.documentUrl = JSON.parse(this.discloseForm.documentUrl);
      }
      this.discloseShow = true;
    },
    // 删除安全交底
    deleteDisclose(row) {
      if (row.id) {
        this.$loading();
        securityDisclosureRemove(row.id).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$loading().close();
        });
      } else {
        this.$message({
          type: "success",
          message: "操作成功",
        });
      }
      this.securityDisclosureList = this.securityDisclosureList.filter(
        (item) => item.id != row.id
      );
    },
    // 查看安全交底
    viewDisclose(row) {
      this.discloseType = "view";
      this.discloseForm = row;
      if (this.discloseForm.documentUrl == "") {
        this.documentUrl = '[]';
      } else {
        this.documentUrl = JSON.parse(this.discloseForm.documentUrl);
      }
      this.discloseShow = true;
    },
    // 保存安全交底
    submitDisclse() {
      this.$refs.discloseForm.validate((valid) => {
        if (valid) {

          if (this.documentUrl.length == 0) {
            this.documentUrl = "[]";
          } else {
            this.discloseForm.documentUrl = JSON.stringify(this.documentUrl);
          }
          if (this.discloseType == "add") {
            this.securityDisclosureList.push(this.discloseForm);
          }
          this.discloseShow = false;
        }
      });
    },
    // 上传成功
    discloseSuc(res) {
      if (res.code == 200) {
        this.documentUrl.push({
          name: res.data.originalName,
          url: res.data.link,
        });
      }
    },
    // 删除
    discloseRem(file) {
      this.documentUrl = this.documentUrl.filter(
        (item) => item.name != file.name
      );
    },
    // 新增安全监管人
    addSupervise() {
      this.userOpentype = "supervise";
      this.operatorSelect = true;
      this.userListShow = true;
      let hasData = [];
      this.$nextTick(() => {
        this.$refs.userDeptMultipleDialog.selectionClear();
      });
      if (this.safetySupervisorList.length > 0) {
        let ids = [];
        this.safetySupervisorList.forEach((item) => {
          hasData.push({id:item.indexId})
          if (item.id && item.id > 0) {
            ids.push(item.id);
          }
        });
        if (ids.length > 0) {
          safetySupervisionRemove(ids.join(","));
        }
        // this.safetySupervisorList = [];
      }
      this.checkedUserData = hasData
    },
    // 删除安全监管人
    deleteSupervise(item) {
      if (item.id && item.id > 0) {
        this.$loading();
        safetySupervisionRemove(item.id)
          .then((res) => {
            this.$message({
              type: "success",
              message: res.data.msg,
              duration: 1000,
            });
          })
          .finally(() => {
            this.$loading().close();
          });
      } else {
        this.$message({
          type: "success",
          message: "操作成功",
          duration: 1000,
        });
      }
      this.safetySupervisorList = this.safetySupervisorList.filter(
        (child) => child.conservatorId !== item.conservatorId
      );
    },
    //作业人员选择
    addOperator() {
      this.userOpentype = 'user';
      this.operatorSelect = true;
      this.userListShow = true;
      let hasData = [];
      this.$nextTick(() => {
        this.$refs.userDeptMultipleDialog.selectionClear();
      })
      if (this.wtUserList.length > 0) {
        let ids = []
        this.wtUserList.forEach(item => {
          hasData.push({id:item.indexId})
          if (item.id && item.id > 0) {
            ids.push(item.id)
          }
        })
        if (ids.length > 0) {
          removeWtUser(ids.join(","))
        }
        // this.wtUserList = []
      }
      this.checkedUserData = hasData
    },
    deleteOperator(item) {
      if (item.id && item.id > 0) {
        this.$loading()
        removeWtUser(item.id).then((res) => {
          this.$message({
            type: "success",
            message: res.data.msg,
            duration: 1000,
          })
        }).finally(() => {
          this.$loading().close()
        });
      } else {
        this.$message({
          type: "success",
          message: "操作成功",
          duration: 1000,
        });
      }
      this.wtUserList = this.wtUserList.filter(child => child.userId !== item.userId)
    },
    wtRiskChanger(row, wtRiskType) {
      if (wtRiskType == "input") {
        row.otherContent = this.wtRiskOther
      } else {
        if (row.optionCode == "other") {
          if (this.wtRiskThow == false) {
            this.wtRiskThow = true;
          } else {
            this.wtRiskThow = false;
          }
        }
      }
    },
    // 获取作业票编号
    getTemplateCode() {
      templateCode().then((res) => {
        if (res.data.code == 200) {
          this.dataForm.ticketCode = res.data.data;
        }
      })
    },
    async initData(id) {
      return new Promise((resolve, reject) => {
        myWtTicketDetail(id).then((res) => {
          let data = res.data.data.data;
          this.businessStatus = data.businessStatus;
          if (data.startTime) {
            data.timeInterval = [data.startTime, data.endTime]
          }
          this.dataForm = data
          this.documentUrl = data.documentUrl && JSON.parse(data.documentUrl);
          var wtRiskList = data.wtRiskList
            .filter((e) => e.checkFlag == 1)
            .map((e) => e.optionCode);
          wtRiskList.forEach((i) => {
            if (i == "other") {
              this.wtRiskThow = false;
            }
          });
          let wtRiskListTemp = this.dataForm.wtRiskList
          this.safetySupervisorList = this.dataForm.safetySupervisorList;
          this.securityDisclosureList = this.dataForm.securityDisclosureList;

          if (this.$refs.superviseImg && this.dataForm.supervision) {
            this.$refs.superviseImg.superviseData.supervision =
              this.dataForm.supervision;
          }
          if (this.$refs.finishWorkImg && this.dataForm.completion) {
            this.$refs.finishWorkImg.finishWorkData.completion =
              this.dataForm.completion;
          }
          if(this.dataForm.wtDisclosureList.length){
            this.$refs.securityDisclosure.initData(this.dataForm.wtDisclosureList);
          }
          this.dataForm.wtRiskList = this.dataForm.wtRiskList.filter(e => e.checkFlag == 1).map(e => e.optionCode)
          if (this.dataForm.wtRiskList.includes('other')) this.wtRiskOther = wtRiskListTemp.find(e => e.optionCode == 'other').otherContent
          this.wtGasList = this.dataForm.wtLimitGasList
          this.wtUserList = this.dataForm.wtUserList
          this.wtSecurityPreventionList = this.dataForm.wtSecurityPreventionList
          if (this.type == "view") {
            this.wtRiskThow = true;
          }
          resolve(this.dataForm)
        })
      });
    },
    submitData(row) {
      this.dataForm.relatedWt = row.ticketCode;
      this.jobTicketDialogShow = false
    },
    relatedWtClick() {
      this.jobTicketDialogShow = true
    },
    ledgerRowClick(row) {
      this.dataForm.ledgerId = row.id
      this.dataForm.limitName = row.limitSpaceName
      this.dataForm.material = row.originalMediaName
      this.ledgerShow = false
    },
    ledgerClick() {
      this.ledgerShow = true
    },
    openUser(type) {
      this.userOpentype = type;
      this.userShow = true;
      let hasData = []
        if (this.userOpentype == "securityDisclosureName"&&this.dataForm.securityDisclosureList) {
          this.dataForm.securityDisclosureList.map(item => {
            hasData.push({userId:item.userId})
          })
        }
        this.checkedUserData = hasData
    },
    openDeptBelow(type) {
      this.deptOpenType = type;
      this.deptBelowShow = true;
    },
    async handleForward() {
      await this.headSave("save")
      this.operatorSelect = false;
      this.userListShow = true;
      this.$nextTick(() => {
        this.$refs.userDeptMultipleDialog.selectionClear();
      })
    },
    async headSave(type = 'save') {
      if (this.wtUserList.length == 0) {
        this.$message.warning('请先选择作业人员!');
        return;
      }
      if (this.safetySupervisorList.length == 0) {
        this.$message.warning("请先选择安全监管人员!");
        return;
      }
      if (this.businessStatus == 'DISCLOSED' && this.securityDisclosureList.length == 0) {
        this.$message.warning("安全交底不能为空!");
        return;
      }
      if (this.businessStatus == 'SUPERVISE' && this.$refs.superviseImg.superviseData.supervision == '') {
        this.$message.warning("现场照片不能为空!");
        return;
      }
      if (this.businessStatus == 'CHECKACCEPT' && this.$refs.finishWorkImg.finishWorkData.completion == '') {
        this.$message.warning("完工验收照片不能为空!");
        return;
      }
      if (!this.dataForm.measureSignatureUrl) {
        this.$message.warning("安全措施签名不能为空!");
        return;
      }

      let emptywtSecurity = this.wtSecurityPreventionList.filter((item) => {
        if (item.securityContent == "") {
          return item;
        }
      });
      if (emptywtSecurity.length != 0) {
        this.$message.warning("请完善安全措施数据!");
        return;
      }
      let riskList = new Set(this.dataForm.wtRiskList)
      this.riskIdentResults.forEach(e => {
        if (riskList.has(e.optionCode)) e.checkFlag = 1
      })
      if (this.dataForm.timeInterval.length > 0) {
        this.dataForm.startTime = this.dataForm.timeInterval[0]
        this.dataForm.endTime = this.dataForm.timeInterval[1]
      }

      let param = {...this.dataForm}
      param.orgId = this.userInfo.dept_id
      param.wtRiskList = this.riskIdentResults
      param.wtSecurityPreventionList = this.wtSecurityPreventionList
      param.wtLimitGasList = this.wtGasList
      param.wtUserList = this.wtUserList;
      param.safetySupervisorList = this.safetySupervisorList;
      param.documentUrl=JSON.stringify(this.documentUrl)
      if (this.$refs.superviseImg) {
        param.supervision = this.$refs.superviseImg.superviseData.supervision;
      }
      if (this.$refs.finishWorkImg) {
        param.completion = this.$refs.finishWorkImg.finishWorkData.completion;
      }

      param.wtDisclosureList = []
      if (this.businessStatus != 'PREPARE') {
          var submit = this.$refs.securityDisclosure.submit();
        if(submit.receive.length == 0 )return  this.$message.error('交底人签名不能为空')

        if (submit.signType) {
            submit.signType.forEach((e) => {
              param.wtDisclosureList.push({
                id: e.id,
                userSign: e.imgUrl,
                userType: "secure",
                signType: '2'
              });
            });
          }
          submit.receive.forEach((e) => {
            param.wtDisclosureList.push({
              id: e.id,
              userSign: e.imgUrl,
              userType: "receive",
              signType: '1'
            });
          });
        }
      param.isSubmit = type
      return new Promise((resolve, reject) => {
        this.$refs.formLayout.validate((valid) => {
          if (valid) {
            this.$loading()
            wtLimitSave(param).then(res => {
              if (res.data.code == 200) {
                if (type != "submit" && type == 'saveData') {
                  this.$message.success("保存成功")
                }
                ;
                if (type === 'submit') {
                  this.$message.success('提交成功')
                  this.headCancel()
                }
                this.initData(res.data.data.ticketCode).then((indata) => {
                  resolve(indata);
                });
              }
            }).finally(() => {
              this.$loading().close()
            })
          }
        })
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back()
    },
    getUserList(data) {
      if (!this.operatorSelect) {
        let userList = data.selectAllData.map(e => {
          return {
            userId: item.userId.toString(),
            userName: e.userName,
            userDept: e.deptId
          }
        })
        let cc = {
          ticketCode: this.dataForm.ticketCode,
          userList: userList
        }
        retransmission(cc).then(res => {
          this.$message({
            type: "success",
            message: "操作成功",
            duration: 1000,
          });
          this.userListShow = false
        })
      } else {
        let userList = []
        if (this.userOpentype == "user") {
          //作业人员选择逻辑
          data.selectAllData.forEach(item => {
            userList.push({
              indexId:item.id,
              ticketCode: this.dataForm.ticketCode,
              userId: item.userId,
              userAccount: item.account,
              userName: item.userName,
              userLicense: item.userLicense,
              userType: this.userOpentype,
              userDept: item.deptName
            })
          })
          this.wtUserList = userList;
        }
        if (this.userOpentype == 'supervise') {
          data.selectAllData.forEach((item) => {
            userList.push({
              indexId:item.id,
              ticketCode: this.dataForm.ticketCode,
              conservatorId: item.userId,
              conservatorName: item.userName,
              conservatorDept: item.deptId
            });
          });
          this.safetySupervisorList = userList;
        }

      }
    },
    //点击取消
    closeUser(){
      this.userShow = false;
    },
    // 选择人员
    getUser(data) {
      this.userShow = false;
      if (this.userOpentype == 'respNickName') {
        this.dataForm.respUserName = data.account;
        this.dataForm.respUserId = data.userId;
        this.dataForm.respNickName = data.userName;
        this.dataForm.respUserDept = data.deptId;
      }
      if (this.userOpentype == 'user') {
        this.dataForm.userCode = data.code;
        this.dataForm.userId = data.userId;
        this.dataForm.userName = data.userName;
      }
      if (this.userOpentype == 'guardian') {
        this.dataForm.guardianCode = data.code;
        this.dataForm.guardianId = data.userId;
        this.dataForm.guardianName = data.userName;
      }
      if (this.userOpentype == "securityDisclosureName") {
        this.dataForm.securityDisclosureName=data.map(item=>item.userName).join(',');
        this.dataForm.securityDisclosureList=data.map(item=>{
          return {
            ...item,
            id:'',
            disclosureDept:item.deptId,
            disclosureName:item.userName,
            disclosureId:item.userId,
          }
        })
      }
      if (this.userOpentype == "disclosureName") {
        this.discloseForm.disclosureName = data.userName;
        this.discloseForm.disclosureId = data.userId;
      }
    },
    getDeptBelow(data) {
      this.deptBelowShow = false;
      if (this.deptOpenType == 'requirOrg') {
        this.dataForm.requirOrg = data.deptName;
      }
      if (this.deptOpenType == 'workOrg') {
        this.dataForm.workOrg = data.deptName;
      }
    },
    addGas() {
      this.wtGasList.push({
        gasName1: '硫化氢', standard1: '<10ppm', sampleResult1: '',
        gasName2: '一氧化碳', standard2: '<50ppm', sampleResult2: '',
        gasName3: '甲烷', standard3: '<20%lel', sampleResult3: '',
        gasName4: '', standard4: '', sampleResult4: '',
        gasName5: '氧气', standard5: '19.5%-23.5%', sampleResult5: '', sampleUser: '',
      })
    },
    handleInputBlur(row) {
      row.edit = false
    },
    openSignature(index, tableName) {
      this.signatureShow = true;
      this.tableName = tableName
      this.tableIndex = index;
    },
    getSignatureImg(imgUrl) {
      if (this.tableName == 'wtGasList') this.wtGasList[this.tableIndex].sampleUser = imgUrl;
      if (this.tableName == 'wtSecurityPreventionList') this.wtSecurityPreventionList[this.tableIndex].confirmUser = imgUrl
      this.signatureShow = false;
      this.$refs.signature.init()
    },
    deleteGas(index, row) {
      if (row.id) {
        this.$loading()
        removeWtLimitGas(row.id).then(res => {
          this.wtGasList = [...this.wtGasList.slice(0, index), ...this.wtGasList.slice(index + 1)];
          this.$message({
            type: "success",
            message: res.data.msg,
            duration: 1000,
          });
        }).finally(() => {
          this.$loading().close()
        });
      } else {
        this.wtGasList = [...this.wtGasList.slice(0, index), ...this.wtGasList.slice(index + 1)];
      }
    },

    addSecure() {
      this.wtSecurityPreventionList.push({
        securityContent: '',
        invovedFlag: '0',
        confirmUser: '',
      })
    },
    deleteSecure(index, row) {
      if (row.id) {
        this.$loading()
        removeWtSecurityPrevention(row.id).then((res) => {
          this.wtSecurityPreventionList = [...this.wtSecurityPreventionList.slice(0, index), ...this.wtSecurityPreventionList.slice(index + 1)];
          this.$message({
            type: "success",
            message: res.data.msg,
            duration: 1000,
          });
        }).finally(() => {
          this.$loading().close()
        });
      } else {
        this.wtSecurityPreventionList = [...this.wtSecurityPreventionList.slice(0, index), ...this.wtSecurityPreventionList.slice(index + 1)];
      }
    },
  }
}
</script>

<style scoped>
::v-deep .el-step__title {
  cursor: pointer;
}

.ticketMain {
  width: 100%;
  height: 100%;
  display: flex;
}

.ticketLeft {
  width: 145px;
  height: 100%;
  border-right: 1px solid #cccccc;
  background-color: #fff;
}

.ticketLeft ::v-deep .head-layout {
  height: 46px;
  line-height: 46px;
}

.ticketRight ::v-deep .head-layout {
  height: 46px;
  line-height: 46px;
}

.ticketStep {
  width: calc(100% - 40px);
  height: 300px;
  padding: 20px;
}

.ticketRight {
  width: calc(100% - 146px);
  height: 100%;
}

.ticketContent {
  width: 100%;
  height: calc(100% - 46px);
  overflow: auto;
  scrollbar-width: none;
}

.ticketDisclose {
  background-color: #fff;
}

.ticket-head {
  position: relative;
}

.ticket-head-btn {
  position: absolute;
  top: 7px;
  left: 150px;
}

.ticket-head-code {
  position: absolute;
  top: 7px;
  left: 400px;
}

.ticketForm {
  padding: 12px;
  background-color: #FFFFFF;
}

.ticketGas {
  margin-top: 12px;
  background-color: #FFFFFF;
}

.signatureImg {
  width: 70px;
  height: 32px;
  /* display: block; */
  margin: auto;
}

::v-deep .el-textarea .el-input__count {
  height: 20px !important;
}
</style>
